import { Box, Stack, Grid, Typography, Button } from '@mui/material';
import { useState, useEffect } from 'react';
import { useAccount } from 'wagmi';
import useDeviceType from '../../../hooks/useDeviceType';

import metaData from '../../../abis/metadata.json';
import dogizenMetaData from '../../../abis/Dogizen_metadata.json';


import GenesisAsideStyled from './GenesisAsideStyled';
import ImageGenesisLogo from '../../../assets/images/genesis-icon.svg';
import ImageDogizenLogo from '../../../assets/images/Dogizen_logo.svg';
import GenesisAvatar from './GenesisAvatar';
import GenesisRightAside from './GenesisRightAside';
import caretUp from '../../../assets/images/caret-up.svg';
import caretDown from '../../../assets/images/caret-down.svg';

import CollectionView from './CollectionView';

const GenesisAside = ({ filteredImages, currentTab, setCurrentTab, setSortBy, sortBy, handleReset}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalNft, setModalNft] = useState(metaData.collection[0]);
  const [currentPage, setCurrentPage] = useState(0);
  const deviceType = useDeviceType();

  /* const filteredImages = metaData.collection; */
  const ITEMS_PER_PAGE = 20;
  const startIndex = currentPage * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentImages = filteredImages.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredImages.length / ITEMS_PER_PAGE);
  const maxVisiblePages = deviceType === 'desktop' ? 7 : 5; // Number of page buttons to show at once

  const handleNextPage = () => {
    if (startIndex + ITEMS_PER_PAGE < filteredImages.length) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handleNameSort= () => {
    if(sortBy != 'name up'){
      setSortBy('name up')
    }
    else{
      setSortBy('name down')
    }
  }

  const handleRaritySort= () => {
    if(sortBy != 'position up'){
      setSortBy('position up')
    }
    else{
      setSortBy('position down')
    }
  }

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleTabChange = (newValue) => {
    handleReset();
    setCurrentTab(newValue);
    setCurrentPage(0);
    setModalNft(metaData.collection[0]);
  }

  const getImagePath = (item) => {
    try {
      switch (currentTab) {
        case 0:
          return require(`../../../assets/images/Moonizen Genesis Thumbnails/${item.image}`);
        case 1:
          return require(`../../../assets/images/Dogizen Thumbnails/${item.image}`); // Example path for Dogizens
        default:
          return require(`../../../assets/images/Moonizen Genesis Thumbnails/${item.image}`); // Fallback path
      }
    } catch (error) {
      console.error(`Image not found for ${item.image} in tab ${currentTab}:`, error);
      return ''; // Return a placeholder or empty string if the image is not found
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Calculate the start and end page numbers
  let startPage = 0;
  let endPage = totalPages - 1;

  // Calculate the range of pages to display in the middle
  const middlePages = maxVisiblePages - 2; // Exclude first and last pages
  const halfMiddle = Math.floor(middlePages / 2);

  if (totalPages > maxVisiblePages) {
    if (currentPage <= halfMiddle) {
      endPage = middlePages; // Show first few pages
    } else if (currentPage + halfMiddle >= totalPages - 1) {
      startPage = totalPages - middlePages - 1; // Show last few pages
    } else {
      startPage = currentPage - halfMiddle; // Center around current page
      endPage = currentPage + halfMiddle;
    }
  }

  // Ensure startPage and endPage do not overlap with the first and last pages
  startPage = Math.max(startPage, 1); // Start from page 1
  endPage = Math.min(endPage, totalPages - 2); // End before the last page

  return (
    <GenesisAsideStyled>
      <CollectionView isOpen={isModalOpen} setIsOpen={setIsModalOpen} nft={modalNft} currentTab={currentTab} />

      <Stack>
        <Stack
            className="title title-bottom-bar"
            direction={'row'}
            spacing={1}
            alignItems={'center'}
          >
          <button className={`tab-button ${currentTab === 0 ? 'gradient-border-box active' : ''}`} onClick={() => handleTabChange(0)}>
            <img className="title-logo" src={ImageGenesisLogo} alt="" />
            <Typography className="genesis-title">Genesis</Typography>
          </button>
          <button className={`tab-button ${currentTab === 1 ? 'gradient-border-box active' : ''}`} onClick={() => handleTabChange(1)}>
            <img className="title-logo" src={ImageDogizenLogo} alt="" />
            <Typography className="genesis-title">Dogizens</Typography>
          </button>
        </Stack>
        <Stack className="options" direction={'row'} spacing={1}>
          <Typography>Sort By:</Typography>
          <Stack className={`rounded-borders ${sortBy.includes('name') ? 'gradient-border-box active': ''}`} direction={'row'} sx={{paddingLeft: 0}}>
            <Typography className="sort-by" onClick={() => handleNameSort(sortBy)}>Number</Typography>
            <img className="caret" src={sortBy==="name up" ? caretUp: '' } />
            <img className="caret" src={sortBy==="name down" ? caretDown: '' } />
          </Stack>
          <Stack className={`rounded-borders ${sortBy.includes('position') ? 'gradient-border-box active': ''}`} direction={'row'}>
            <Typography className="sort-by" onClick={() => handleRaritySort(sortBy)}>Rarity</Typography>
            <img className="caret" src={sortBy==="position up" ? caretUp: '' } />
            <img className="caret" src={sortBy==="position down" ? caretDown: '' } />
          </Stack>
        </Stack>
        <Grid container spacing={4} className="content">
          <Grid item md={12} lg={9} className="gradient-border-box genesis-box">
            <Box className="gradient-border-box genesis-wrapper">
              <Grid container spacing={2.5} justifyContent="center" alignItems="center">
                {currentImages.map((item, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xxl={12 / 5}
                      xl={12 / 5}
                      lg={3}
                      md={3}
                      sm={3}
                      xs={6}
                      className="avatar-item"
                    >
                      <GenesisAvatar
                        onClick={() => (setIsModalOpen(true), setModalNft(item))}
                        src={getImagePath(item)}
                        rarity={item.rarity}
                        name={item.name}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
            <Stack className="nav-buttons" 
            direction="row" 
            spacing={2} 
            justifyContent="space-between" 
            mt={2} >
              <Button className="nav-button" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0}>Prev</Button>
              {<Stack direction="row" spacing={1} mt={2}>
                <Button
                  key={0}
                  className={`page-button ${currentPage === 0 ? "active" : ""}`} 
                  onClick={() => handlePageChange(0)}
                  disabled={currentPage === 0}
                  variant={currentPage === 0 ? 'contained' : 'outlined'}
                >
                  1
                </Button>

                { currentPage > 3 ? (<Typography className="page-button">...</Typography>): (<></>)}

                {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                  <Button
                    key={startPage + index}
                    className={`page-button ${currentPage === startPage + index ? "active" : ""}`} 
                    onClick={() => handlePageChange(startPage + index)}
                    disabled={currentPage === startPage + index}
                    variant={currentPage === startPage + index ? 'contained' : 'outlined'}
                  >
                    {startPage + index + 1}
                  </Button>
                ))}

                { currentPage < totalPages - 4 ? (<Typography className="page-button">...</Typography>): (<></>)}

                {totalPages > 1 && (
                  <Button
                    key={totalPages - 1}
                    className={`page-button ${currentPage === endPage+1 ? "active" : ""}`} 
                    onClick={() => handlePageChange(totalPages - 1)}
                    disabled={currentPage === totalPages - 1}
                    variant={currentPage === totalPages - 1 ? 'contained' : 'outlined'}
                  >
                    {totalPages}
                  </Button>
                )}
              </Stack>}
              <Button className="nav-button" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages - 1}>Next</Button>
            </Stack>
          </Grid>
          <Grid item md={12} lg={3} className="genesis-right-wrapper" style={{ flexGrow: 1 }}>
            <GenesisRightAside />
          </Grid>
        </Grid>
      </Stack>
    </GenesisAsideStyled>
  );
};

export default GenesisAside;
