import styled from '@emotion/styled';

import starry from '../../../assets/images/Card Backgrounds/Nebula-background-blue.png';

const GenesisAvatarStyled = styled.div`
  .gradient-border-box {
    border-radius: 5px;
    width: 100%;
    height: calc(0.7368 * 8.5vw + 1.487px);
    max-height: 207px;



    .bg-box {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .Mythic {
    background-image: linear-gradient(135deg, #bf9b30 37%, #a67c00 40%,#FFFFFF 43%, #FFD700 50%, #bf9b30 60%); /* linear-gradient(135deg, #FFD700 0%, #FFF000 37%, #FFFFFF 40%, #FFF000 45%, #FFD700 50%) */
    background-size: 300% 300%; // To enable animation effect
    animation: shine 3s linear infinite; // Reference the CSS animation
    color: ${(props) => props.theme.palette.fontColor.secondary};
  }

  .Legendary {
    background-image: url(${starry}); /* linear-gradient(135deg, #FFD700 0%, #FFF000 37%, #FFFFFF 40%, #FFF000 45%, #FFD700 50%) */
    background-size: 400% 400%; // To enable animation effect
    animation: nebula 8s linear infinite; // Increased duration for smoother transitions
    color: ${(props) => props.theme.palette.fontColor.secondary};
  }

  .Epic {
    background-color: #7B3FE4;
    background-size: 300% 300%; // To enable animation effect
    /* animation: shine 3s linear infinite; // Reference the CSS animation */
    color: ${(props) => props.theme.palette.fontColor.secondary};
  }

  .Rare {
    background-color: #47c3c1; 
    background-size: 300% 300%; // To enable animation effect
    animation: shine 3s linear infinite; // Reference the CSS animation
    color: ${(props) => props.theme.palette.fontColor.secondary};
  }

  .Common{
    background-color: #07485B;
    background-size: 300% 300%; // To enable animation effect
    animation: shine 3s linear infinite; // Reference the CSS animation
    color: ${(props) => props.theme.palette.fontColor.secondary};
  }
  
  .genesis-avatar-box {
    margin-left: auto;
    margin-right: auto;

    .rarity-position-inner{
      display: flex;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.2px;
      font-family: Geom;
      margin-top: -20px;
      justify-content: center;
      align-text: center;
    }
    
    .rarity-position-inner:first-of-type {
      padding-left: 5px; /* Adjust the value as needed */
    }
    
    .rarity-position-inner:last-child {
      padding-right: 5px; /* Adjust the value as needed */
    }
  }

  @keyframes shine {
    0% {
      background-position: 100% 50%;
    }
    25%{
      background-position: 50% 50%;
    }
    90% {
      background-position: -25% 50%;
    }
    100% {
      background-position: -40% 50%;
    }
  }

  @keyframes galaxy {
    0% {
      background-position: -25% 50%;
    }

    100% {
      background-position: 125% 50%;
    }

  }

  @keyframes nebula {
    0% {
      background-position: 0% 100%;
    }
    100% {
      background-position: 110% 10%;
    }
  }

  @media (max-width: 1770px){
    .genesis-avatar-box .rarity-position-inner{
      font-size:14px;
    }
  }

  @media (max-width: 1440px){
    .genesis-avatar-box{
      width: 80px;
      height: 103px;

     .rarity-position-inner{
        margin-top: -10px;
        font-size: 10px;
      }
    }
    .gradient-border-box{
      height: 110px;
    }
  }

  @media (max-width: 1365px) {
    .gradient-border-box {
      height: 88px;
    }
  }

  @media (max-width: 1140px) {
    .genesis-avatar-box .rarity-position-inner{
      font-size: 8px;
    }
  }

  @media (max-width: 991px) {
    .gradient-border-box {
      height: 125px;
    }

    .genesis-avatar-box .rarity-position-inner{
        margin-top: -15px;
        font-size: 14px;
      }
    }
  }
`;

export default GenesisAvatarStyled;
