import styled from '@emotion/styled';

import starry from '../../../assets/images/Card Backgrounds/stars.png';

const WalletAvatarStyled = styled.div`
  .gradient-border-box {
    border-radius: 5px;
    width: 100%;
    height: calc(0.7368 * 8.5vw + 1.487px);
    max-height: 207px;



    .bg-box {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .Mythic {
    background-image: linear-gradient(135deg, #FFD700 47%, #FFFFFF 50%,#FFD700 53%); /* linear-gradient(135deg, #FFD700 0%, #FFF000 37%, #FFFFFF 40%, #FFF000 45%, #FFD700 50%) */
    background-size: 300% 300%; // To enable animation effect
    animation: shine 3s linear infinite; // Reference the CSS animation
  }

  .Legendary {
    background-image: url(${starry}); /* linear-gradient(135deg, #FFD700 0%, #FFF000 37%, #FFFFFF 40%, #FFF000 45%, #FFD700 50%) */
    background-size: 300% 300%; // To enable animation effect
    animation: galaxy 5s linear infinite; // Reference the CSS animation
  }

  .Epic {
    background-image: linear-gradient(135deg, #000000 47%, #FFFFFF 50%,#000000 53%); /* linear-gradient(135deg, #FFD700 0%, #FFF000 37%, #FFFFFF 40%, #FFF000 45%, #FFD700 50%) */
    background-size: 300% 300%; // To enable animation effect
    animation: shine 3s linear infinite; // Reference the CSS animation
  }

  .Rare {
    background-image: linear-gradient(135deg, #000000 47%, #FFFFFF 50%,#000000 53%); /* linear-gradient(135deg, #FFD700 0%, #FFF000 37%, #FFFFFF 40%, #FFF000 45%, #FFD700 50%) */
    background-size: 300% 300%; // To enable animation effect
    animation: shine 3s linear infinite; // Reference the CSS animation
  }

  .Common{
    background-image: linear-gradient(135deg, #000000 47%, #FFFFFF 50%,#000000 53%); /* linear-gradient(135deg, #FFD700 0%, #FFF000 37%, #FFFFFF 40%, #FFF000 45%, #FFD700 50%) */
    background-size: 300% 300%; // To enable animation effect
    animation: shine 3s linear infinite; // Reference the CSS animation
  }
  
  .genesis-avatar-box {
    margin-left: auto;
    margin-right: auto;
  }

  @keyframes shine {
    0% {
      background-position: 100% 50%;
    }
    15% {
      background-position: -25% 50%;
    }
    100% {
      background-position: -25% 50%;
    }
  }

  @keyframes galaxy {
    0% {
      background-position: 0% 50%;
    }

    100% {
      background-position: 125% 50%;
    }

  }

  @media (max-width: 1440px){
    .gradient-border-box{
      height: 110px;
    }
  }

  @media (max-width: 1365px) {
    .gradient-border-box {
      height: 88px;
    }
  }

  @media (max-width: 991px) {
    .gradient-border-box {
      height: 125px;
    }
  }
`;

export default WalletAvatarStyled;
