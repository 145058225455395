import { Box } from '@mui/material';

import FilterAsideStyled from './FilterAside.styled';
import Filter from '../../Filter';
import FilterResultBox from './FilterResultBox';

const FilterAside = ({ handleFilterChange, handleNumSearch, handleReset, currentTab, numFilter, setNumFilter, searchInput, setSearchInput, filters, setFilters}) => {
  return (
    <FilterAsideStyled>
      <Box>
        <Filter onNumberSearch={handleNumSearch} 
        handleReset={handleReset} 
        numFilter={numFilter} 
        setNumFilter={setNumFilter} 
        setSearchInput={setSearchInput}
        searchInput={searchInput}/>
        <FilterResultBox onFilterChange={handleFilterChange} currentTab={currentTab} filters={filters} setFilters={setFilters} />
      </Box>
    </FilterAsideStyled>
  );
};

export default FilterAside;
