import styled from '@emotion/styled';

const GalleryStyled = styled.div`

.container{
    margin-top: 25px;
    .pip{
        display: flex;
        width: 100%;
        height: 85vh;
        margin: auto;
        border: none;
    }
}
`;

export default GalleryStyled;