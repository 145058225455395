import { Box, Stack, Typography } from '@mui/material';

const Accordion = ({category, activeIndex, onAccordionClick, onSelect}) => {
    const isActive = category.index === activeIndex;

    return(
        <Box className="accordion-item big-title" key={category.title}>
            <Box className="accordion-title" onClick={()=> onAccordionClick(category.index)}>
                <Stack className="title-pieces" direction="row">
                    <Box className="section-title">{category.name}</Box>
                    <Box className="display-button">{isActive? "v":"<"}</Box>
                </Stack>
            </Box>
            {isActive && <Box className="accordion-content">{category.episodes.map((episode) => (
                <Typography className="description" key={episode.name} onClick={() => onSelect(episode)}>
                    {episode.title}
                </Typography>
            ))}</Box>}
        </Box>
    )
}


export default Accordion;