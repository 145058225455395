import styled from '@emotion/styled';

const GenesisAsideStyled = styled.div`
  padding-top: 28px;

  .title {
    padding-bottom: 8px !important;

    .genesis-title {
      color: ${(props) => props.theme.palette.fontColor.secondary};
      font-size: 20px;
      font-weight: 400;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.2px;
      font-family: Space Age;
    }

    .title-logo {
      height: 59px;
    }

    .tab-button {
      background: inherit;
      border: none;
      cursor: pointer;
      padding: 10px;
      margin-bottom: -9px;
      transition: background 0.3s;
      border-radius: 5px 5px 0 0;
    }

    .tab-button.active {
      background-color: rgba(7, 72, 91, 0.6);
      color: white;
    }
  }

  .options{
    padding: 5px 0px;
    color: white;
  }

  .rounded-borders{
    border-radius: 5px;
  }

  .sort-by{
    cursor:pointer;
    margin-left: 5px;
    z-index: 500;
  }

  .active {
      background-color: rgba(7, 72, 91, 0.6);
      color: white;
    }

  .caret{
    height:24px;
    margin-top: -2px;
  }

  .content {
    padding-top: 30px;
    padding-left: 32px;
    padding-bottom: 40px;
    height: 100%;

    .genesis-box {
      padding: 20px 30px 40px;
      height: calc(5 * (0.7368* 10vw + 1.487px));
      background: linear-gradient(
        180deg,
        rgba(7, 72, 91, 0.6) 0%,
        rgba(7, 72, 91, 0.2) 100%
      );
      background-blend-mode: multiply;

      .genesis-wrapper {
        padding: 12px 20px;
        border-radius: 10px;
        margin: auto;
        height: calc(5 * (0.7368* 9vw + 1.487px));
        background: #011c23;

        .gradient-border-box{
          width: calc(0.7 * (0.7368* 9.5vw + 1.487px));
          height: calc(0.7368* 9.5vw + 1.487px);
          transition: transform 0.3s ease;
        }

        .gradient-border-box:hover {
          transform: scale(1.10);
        }
      }

      .nav-buttons{
        padding-top: 0px;
        width: 100%;
      }

      .nav-button{
        background-color: rgba(10,10,10,0.1);
        color: #47c3c1;
        padding: 0px;
        font-family: Space Ranger;
        font-weight: 400;
      }

      .page-button{
        background-color: rgba(10,10,10,0.1);
        color: #47c3c1;
        padding: 0px;
        font-family: Space Ranger;
        font-weight: 400;
        border: none;
        min-width: 0px;
        width: 60px;
      }
      
      .active{
        color:#07485B;
      }
      .genesis-right-wrapper {
        padding-top: 0;
      }
  }

  @media (max-width: 1440px) {
    .genesis-box{ 
      height: calc(5 * (0.7368* 10.5vw + 11.487px));
      .genesis-wrapper{
        height: calc(5 * (0.7368* 9.5vw + 11.487px));
      }

      .nav-buttons {
        margin-top: 0px;
        padding-top: 2px;
      }

      .page-button{
        width:30px;
      }
    }
  }

  @media (max-width: 1200px) {
      .genesis-box{ 
      height: calc(6 * (0.7368* 10.5vw + 11.487px));
        .genesis-wrapper{
          height: calc(6 * (0.7368* 9.5vw + 11.487px));
        }
        .page-button{
          width: 10px;
        }
    }
  }

  @media (max-width: 1200px) {
    .gradient-border-box .bg-box {
      min-width: unset;
    }
  }

  @media (max-width: 991px) {
    .genesis-box {
      height: calc( 5 * 140px + 80px);

      .genesis-wrapper{
        height: calc( 5 * 140px + 20px);

       .gradient-border-box {
          height: 120px;
          width: 90px;
        }
      }

      .nav-buttons{
        margin-top: 5px;
      }

      .page-button{
        width: 20px;
      }
    }
  }

  @media (max-width: 765px) {
    .genesis-right-wrapper{
      padding-left: 0px;
    }
  }

  @media (max-width: 575px) {

    .genesis-box {
      height: calc( 10 * 140px + 80px);

      .genesis-wrapper{
        height: calc( 10 * 140px + 20px);
      }

      .page-button{
        width: 10px;
        margin-left: 12px;
      }
    }
  }

  @media (max-width: 415px) {
    .genesis-box .nav-buttons{
      margin-left: -15px;
      margin-right: -30px;
    }
  }
`;

export default GenesisAsideStyled;
