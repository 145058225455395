import { Box, FormControl, MenuItem, Select } from '@mui/material';
import FilterResultBoxStyled from './FilterResultBox.styled';
import GradientBorderBox from '../../GradientBorderBox';
import { useState, useEffect } from 'react';

import metaData from '../../../abis/metadata.json';
import Dogizens from '../../../abis/Dogizen_metadata.json';

const FilterResultBox = ({ onFilterChange, currentTab, filters, setFilters }) => {
  // Initial state for all dropdowns
  const [currentData, setCurrentData] = useState(metaData)
  const [filterOptions, setFilterOptions] = useState({
    backgrounds: [
      "Celestial Space Ichor (51)",
      "Inter Cosmic Caeruleum (51)",
      "Celestial Space Saturn (50)",
      "Ether Space Caeruleum (48)",
      "Inter Cosmic Rubrum (46)",
      "Celestial Space Nova (45)",
      "Celestial Space Caelus (42)",
      "Celestial Space Celeste (42)",
      "Celestial Space Hades (42)",
      "Celestial Space Lunaris (40)",
      "Celestial Space Tetroxide (38)",
      "Ether Space Rubrum (38)",
      "Celestial Space Enigmara (37)",
      "Celestial Space Zenith (36)",
      "Celestial Space Tranquil (28)",
      "Ether Space Divergence (24)",
      "Celestial Space Noxius (23)",
      "Celestial Space Solstice (22)",
      "Ether Space Flavum (21)",
      "Celestial Space Bismuth (19)",
      "Celestial Space Etherion (18)",
      "Celestial Space Poseidon (18)",
      "Ether Space Aurantium (18)",
      "Inter Cosmic Viridium (18)",
      "Ether Space Wave (17)",
      "Celestial Space Nitrous (16)",
      "Ether Space Paradox (16)",
      "Celestial Space Serenity (15)",
      "Ether Space Viridium (14)",
      "Inter Cosmic Aurantium (13)",
      "Celestial Space Aurora (12)",
      "Celestial Space Orion (11)",
      "Celestial Space Porphyra (11)",
      "Inter Cosmic Porphúra (11)",
      "Celestial Space Royal (10)",
      "Celestial Space Bliss (10)",
      "Celestial Space Flare (9)",
      "Celestial Space Radiance (9)",
      "Celestial Space Noir (7)",
      "Celestial Space Solara (4)",
    ],
    sigils: [
      "Limbo (241)",
      "Sigil of Cycle (124)",
      "Sigil of Construct (120)",
      "Sigil of The Cosmos (117)",
      "Sigil of Wisdom (117)",
      "Sigil of Luna (67)",
      "Sigil of Convergence (64)",
      "Sigil of the Sun (62)",
      "Sigil of Moonblessed (46)",
      "Sigil of Exalted (42)",
    ],
    uniforms: [
      "Emissary (253)",
      "Slayer (165)",
      "Spawner (163)",
      "Pilot (93)",
      "Deviser (72)",
      "Legionnaire (63)",
      "Reaper (63)",
      "Ensign (62)",
      "Savant (62)",
      "Emissary Aurum (1)",
      "Reaper Aurum (1)",
      "Slayer Aurum (1)",
      "Spawner Aurum (1)",
    ],
    helmets: [
      "Selene Alpha (115)",
      "Opifex Alpha (114)",
      "Apex Alpha (111)",
      "Andros Alpha (107)",
      "Guerrilla Alpha (99)",
      "Apex Delta (40)",
      "Opifex Beta (33)",
      "Andros Gamma (33)",
      "Andros Beta (31)",
      "Opifex Gamma (31)",
      "Selene Beta (30)",
      "Selene Delta (27)",
      "Apex Beta (26)",
      "Opifex Delta (25)",
      "Guerrilla Beta (23)",
      "Guerrilla Epsilon (23)",
      "Apex Gamma (20)",
      "Andros Epsilon (19)",
      "Apex Epsilon (19)",
      "Guerrilla Delta (16)",
      "Andros Delta (15)",
      "Guerrilla Gamma (15)",
      "Selene Gamma (14)",
      "Opifex Epsilon (13)",
      "Chrysus (1)",
    ],
    rarity: [ 'Common (248)',
      'Rare (248)',
      'Epic (248)',
      'Mythic (248)',
      'Legendary (5)',
    ],
  })

  useEffect(()=>{
    getFiltersForTab()
  }, [currentTab])

  const getFiltersForTab = () => {
    switch(currentTab){
      case 0:
        setCurrentData(metaData);
        setFilters({
          backgrounds: '',
          sigils: '',
          helmets: '',
          uniforms: '',
          rarity: '',
        });
        setFilterOptions({
          backgrounds: [
            "Celestial Space Ichor (51)",
            "Inter Cosmic Caeruleum (51)",
            "Celestial Space Saturn (50)",
            "Ether Space Caeruleum (48)",
            "Inter Cosmic Rubrum (46)",
            "Celestial Space Nova (45)",
            "Celestial Space Caelus (42)",
            "Celestial Space Celeste (42)",
            "Celestial Space Hades (42)",
            "Celestial Space Lunaris (40)",
            "Celestial Space Tetroxide (38)",
            "Ether Space Rubrum (38)",
            "Celestial Space Enigmara (37)",
            "Celestial Space Zenith (36)",
            "Celestial Space Tranquil (28)",
            "Ether Space Divergence (24)",
            "Celestial Space Noxius (23)",
            "Celestial Space Solstice (22)",
            "Ether Space Flavum (21)",
            "Celestial Space Bismuth (19)",
            "Celestial Space Etherion (18)",
            "Celestial Space Poseidon (18)",
            "Ether Space Aurantium (18)",
            "Inter Cosmic Viridium (18)",
            "Ether Space Wave (17)",
            "Celestial Space Nitrous (16)",
            "Ether Space Paradox (16)",
            "Celestial Space Serenity (15)",
            "Ether Space Viridium (14)",
            "Inter Cosmic Aurantium (13)",
            "Celestial Space Aurora (12)",
            "Celestial Space Orion (11)",
            "Celestial Space Porphyra (11)",
            "Inter Cosmic Porphúra (11)",
            "Celestial Space Royal (10)",
            "Celestial Space Bliss (10)",
            "Celestial Space Flare (9)",
            "Celestial Space Radiance (9)",
            "Celestial Space Noir (7)",
            "Celestial Space Solara (4)",
          ],
          sigils: [
            "Limbo (241)",
            "Sigil of Cycle (124)",
            "Sigil of Construct (120)",
            "Sigil of The Cosmos (117)",
            "Sigil of Wisdom (117)",
            "Sigil of Luna (67)",
            "Sigil of Convergence (64)",
            "Sigil of the Sun (62)",
            "Sigil of Moonblessed (46)",
            "Sigil of Exalted (42)",
          ],
          uniforms: [
            "Emissary (253)",
            "Slayer (165)",
            "Spawner (163)",
            "Pilot (93)",
            "Deviser (72)",
            "Legionnaire (63)",
            "Reaper (63)",
            "Ensign (62)",
            "Savant (62)",
            "Emissary Aurum (1)",
            "Reaper Aurum (1)",
            "Slayer Aurum (1)",
            "Spawner Aurum (1)",
          ],
          helmets: [
            "Selene Alpha (115)",
            "Opifex Alpha (114)",
            "Apex Alpha (111)",
            "Andros Alpha (107)",
            "Guerrilla Alpha (99)",
            "Apex Delta (40)",
            "Opifex Beta (33)",
            "Andros Gamma (33)",
            "Andros Beta (31)",
            "Opifex Gamma (31)",
            "Selene Beta (30)",
            "Selene Delta (27)",
            "Apex Beta (26)",
            "Opifex Delta (25)",
            "Guerrilla Beta (23)",
            "Guerrilla Epsilon (23)",
            "Apex Gamma (20)",
            "Andros Epsilon (19)",
            "Apex Epsilon (19)",
            "Guerrilla Delta (16)",
            "Andros Delta (15)",
            "Guerrilla Gamma (15)",
            "Selene Gamma (14)",
            "Opifex Epsilon (13)",
            "Chrysus (1)",
          ],
          rarity: [ 'Common (248)',
            'Rare (248)',
            'Epic (248)',
            'Mythic (248)',
            'Legendary (5)',
          ],
        });
        break;
      case 1:
        setCurrentData(Dogizens);
        setFilters({
          backgrounds:'',
          helmets:'',
          uniforms:'',
          rarity: '',
        });
        setFilterOptions({
          backgrounds: [
            "Royal (38)",
            "Lunaris (34)",
            "Roseus (30)",
            "Caeruleum (28)",
            "Violaceum (27)",
            "Menta (25)",
            "Ichor (23)",
            "Roseum (22)",
            "Caelis (18)",
            "Chuid (17)",
            "Noxius (17)",
            "Viridium (17)",
            "Bliss (16)",
            "Caeruleus (15)",
            "Ichorium (15)",
            "Purpura (15)",
            "Rubrum (15)",
            "Nitrous (14)",
            "Bismuth (13)",
            "Flare (10)",
            "Noir (9)",
            "Royaleum (9)",
            "Celeste (8)",
            "Enigmara (8)",
            "Poseidon (8)",
            "Tranquil (8)",
            "Flavium (7)",
            "Viridis (7)",
            "Dogesium (6)",
            "Limbo",
            "Satoshium (6)",
            "Porphyra (5)",
            "Aurora (2)",
            "Paradox (2)",
          ],
          helmets: [
            "Opifex Symbocar (47)",
            "Primatus Mymbocar (30)",
            "Opifex Mymbocar (29)",
            "Cognis Mymbocar (28)",
            "Scout Wraithcar (28)",
            "Guerrilla Wraithcar (25)",
            "Scout Mymbocar (24)",
            "Guerrilla Symbocar (22)",
            "Guerrilla Mymbocar (19)",
            "Envoy Mymbocar (18)",
            "Guerrilla Alpha (18)",
            "Primatus Wraithcar (18)",
            "Envoy Wraithcar (17)",
            "Apex Symbocar (16)",
            "Envoy Symbocar (15)",
            "Apex Wraithcar (14)",
            "Primatus Symbocar (13)",
            "Selene Alpha (13)",
            "Cognis Wraithcar (11)",
            "Cognis Symbocar (10)",
            "Selene Wraithcar (9)",
            "Apex Alpha (8)",
            "Selene Symbocar (7)",
            "Bellator Mymbocar (6)",
            "Bellator Symbocar (6)",
            "Apex Mymbocar (5)",
            "Selene Mymbocar (5)",
            "Andros Symbocar (4)",
            "Andros Wraithcar (4)",
            "Opifex Alpha (3)",
            "Opifex Wraithcar (3)",
            "Bellator Wraithcar (2)",
            "Andros Mymbocar (1)",
          ],
          uniforms: [
            "Spawner (114)",
            "Emissary (92)",
            "Ensign Mymbocar (68)",
            "Ensign (66)",
            "Pilot (40)",
            "Slayer Wraithcar (40)",
            "Emissary Symbocar (35)",
            "Slayer (27)",
            "Monitor (18)",
          ],
          rarity: [
            "Common ()",
            "Rare (50)",
            "Epic (50)",
            "Mythic (50)",
          ],
        });
        break;
      default:
        setCurrentData(metaData);
        setFilters({
          backgrounds:'',
          helmet:'',
          uniforms:'',
          sigils:'',
          rarity:'',
        })
        setFilterOptions(filterOptions)
    }
  }

  const handleChange = (event, filterName) => {
    const newFilters = {
      ...filters,
      [filterName]: event.target.value
    };
    setFilters(newFilters);
    
    // Only include non-empty values in the result
    const filterResult = Object.entries(newFilters)
      .reduce((acc, [key, value]) => {
        if (value) {
          acc[key] = value.split("(")[0].trim(); // Slice the name before "("
        }
        return acc;
      }, {});

    console.log(filterResult)

    // Add rarity filter if it exists in the newFilters
    if (newFilters.rarity) {
      const rarityRank = currentData.collection.find(item => item.rarity.rank === newFilters.rarity.split("(")[0].trim());
      if (rarityRank) {
        filterResult.rarity = rarityRank.rarity.rank; // or any other property you want to include
      }
    }
    onFilterChange(filterResult);
  };

  return (
    <FilterResultBoxStyled>
      <GradientBorderBox className="filter-result-box">
        <Box className="filters-wrapper">
          {Object.keys(filters).map((filterName) => (
            <FormControl key={filterName} className="filter-control">
              <Select
                value={filters[filterName]}
                onChange={(e) => handleChange(e, filterName)}
                displayEmpty
                className="filter-select"
                sx={{
                  bgcolor: 'transparent',
                  color: '#fff',
                  '& .MuiSelect-icon': {
                    color: '#fff',
                  },
                  '&:before': {
                    borderColor: '#fff',
                  },
                  '&:after': {
                    borderColor: '#fff',
                  },
                  '&:hover': {
                    bgcolor: 'transparent',
                  },
                  '&.MuiSelect-root.Mui-focused': {
                    bgcolor: '#333',
                  },
                }}
              >
                <MenuItem value="" sx={{ bgcolor: '#333', color: '#fff' }}>
                  <em>{filterName.charAt(0).toUpperCase() + filterName.slice(1)}</em>
                </MenuItem>
                {filterOptions[filterName].map((option) => (
                  <MenuItem 
                    key={option} 
                    value={option} 
                    sx={{ 
                      bgcolor: filters[filterName] === option ? '#333' : '#333',
                      color: filters[filterName] === option ? '#fff' : '#fff',
                      '&:hover': { bgcolor: '#444' },
                    }}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ))}
        </Box>
      </GradientBorderBox>
    </FilterResultBoxStyled>
  );
};

export default FilterResultBox;
