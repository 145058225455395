import styled from '@emotion/styled';

const CollectionViewStyled = styled.div`

  .spacer{
    margin-top: 30px !important;
  }

  .modal-inner-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 862px;
    height: 95vh;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background: #47c3c1;
      background-blend-mode: multiply;
    }

    .close-button {
      border-radius: 10px;
      position: relative;
      width: 15vw;
      margin: auto;
      font-family: Poppins;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      font-size: 13px;
      letter-spacing: 0.13px;
      color: ${(props) => props.theme.palette.fontColor.primary};
      background: ${(props) => props.theme.palette.background.secondary};
    }

    .modal-inner {
      border-radius: 4px;
      background: linear-gradient(
        180deg,
        rgba(4, 48, 61, 1) 0%,
        rgba(3, 31, 39, 1) 100%
      );

      .modal-content {
        padding: 51px 38px 44px 38px;

        .top-header-box {
          padding: 10px 0px 20px 38px;
          background: ${(props) => props.theme.palette.background.darkgreen};
          border-radius: 10px;

          .centering{
            margin-top: auto;
            margin-bottom: auto;

            .image {
              width: 290px;
              height: 290px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          .top-header-desc {
            p {
              color: #fff;
              font-family: Poppins;
              font-style: normal;
              font-weight: 300;
              line-height: normal;
              font-size: 13px;
              letter-spacing: 0.13px;
            }

            .bg-title {
              font-size: 32px;
              letter-spacing: 0.32px;
              font-weight: 700;
            }

            .sm-title {
              font-size: 24px;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .modal-inner-wrapper {
      width: 90%;
    }
  }

  @media (max-width: 768px) {
    .top-box {
      display: block;

      .centering .image {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .modal-inner-wrapper .modal-inner .modal-content .top-header-box {
      padding: 10px;
    }

    .modal-inner-wrapper .close-button{
      height: 40px;
    }

    .modal-inner-wrapper
      .modal-inner
      .modal-content
      .top-header-box
      .top-header-desc {
      margin-left: 0px;
      margin-top: 20px;
    }

    .modal-inner-wrapper
      .modal-inner
      .modal-content
      .top-header-box
      .top-header-desc
      .bg-title {
      font-size: 22px;
    }
    .modal-inner-wrapper
      .modal-inner
      .modal-content
      .top-header-box
      .top-header-desc
      .sm-title {
      font-size: 20px;
    }
  }

  @media (max-width: 574px) {
    .modal-inner-wrapper .modal-inner .modal-content .top-header-box .centering .image {
      width: 220px;
      height: 220px;
    }

    .modal-inner-wrapper .close-button{
      height: 60px;
    }
  }
`;

export default CollectionViewStyled;
