import { useState, useEffect } from 'react';

const getDeviceType = () => {
    const userAgent = navigator.userAgent || window.opera;

    const isMobile = /android|iPhone|iPad|iPod|BlackBerry|Opera Mini|IEMobile|WPDesktop/i.test(userAgent);

    return isMobile ? 'mobile' : 'desktop';
}

const useDeviceType = () => {
    const [deviceType, setDeviceType] = useState(getDeviceType());

    useEffect(()=>{
        const handleResize = () => {
            setDeviceType(window.innerWidth <=768 ? 'mobile': 'desktop');
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return deviceType
}

export default useDeviceType;