import GenesisAvatarStyled from './GenesisAvatarStyled';
import GradientBorderBox from '../../GradientBorderBox';
import { Box, Button, Typography, Tooltip } from '@mui/material';

const GenesisAvatar = ({ onClick, src, rarity, name }) => {
  return (
    <GenesisAvatarStyled>
      <GradientBorderBox className={`genesis-avatar-box ${rarity.rank}`}>
        {/* <Tooltip title={`MOONIZEN ${name} RANK: ${rarity.rank}`} arrow> */}
          <Button
            onClick={() => onClick()}
            className="bg-box"
            sx={{ backgroundImage: `url(${src})`, 
              WebkitMaskImage: `url("data:image/svg+xml,%3Csvg width='100' height='120' viewBox='0 0 100 120' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0' y='0' width='100' height='120' rx='15' ry='15' fill='black'/%3E%3C/svg%3E")`,
              maskImage: `url("data:image/svg+xml,%3Csvg width='100' height='120' viewBox='0 0 100 120' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0' y='0' width='100' height='120' rx='15' ry='15' fill='black'/%3E%3C/svg%3E")`,
              maskSize: '90%',
              maskRepeat: 'no-repeat',
              maskPosition: 'center top 5px',
              WebkitMaskSize: '90%',
              WebkitMaskRepeat: 'no-repeat',
              WebkitMaskPosition: 'center top 5px',
            }}
          />
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography className='rarity-position-inner'>{name}</Typography>
            <Typography className='rarity-position-inner'>{rarity.position}</Typography>
          </Box>
        {/* </Tooltip> */}
      </GradientBorderBox>
    </GenesisAvatarStyled>
  );
};

export default GenesisAvatar;

