import styled from '@emotion/styled';

const VideoUnitStyled = styled.div`
    .holder{
        margin-top: 24px;
        background: linear-gradient(
        180deg,
        rgba(7, 72, 91, 0.6) 0%,
        rgba(7, 72, 91, 0.2) 100%
        );
        background-blend-mode: multiply;
        height: 535px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .content{
        width: 100%;
        height:100%;
    }
    
    .video-list-title{
        margin-top: -55px;
    }

    .video-player{
        height: 95%;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        background: rgba(0,0,0,0.7);
        padding: 0px;
    }

    .test{
        height: 90%;
        background: rgba(0,0,0,0.6);
        margin: 60px 25px 0px 25px;
        border: 1px white solid;
    }

    .rounded-border{
        border-radius: 0.5rem;
    }

    @media(max-width:900px){
        .test{
            width: 100%;
        }

        .media-adjustor .gradient-border-box .gradient-border-box-content{
            margin-left: 10px;
            display:flex;
            justify-content:center;
        }

        .video-player{
            height: auto;
            width: 95%;
            margin: 20px 25px 0px 25px;
        }
    }
`;

export default VideoUnitStyled;