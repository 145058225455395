import { useState, useEffect } from 'react';
import { Box, Stack, Grid, Typography } from '@mui/material';

import metaData from '../../abis/metadata.json';
import dogizenMetaData from '../../abis/Dogizen_metadata.json';
import useDeviceType from '../../hooks/useDeviceType';

import CollectionStyled from './index.styled';
import FilterAside from '../../components/Pages/Collection/FilterAside';
import GenesisAside from '../../components/Pages/Collection/GenesisAside';
import { connectorsForWallets } from '@rainbow-me/rainbowkit';

const Collection = () => {

  const [currentTab, setCurrentTab] = useState(0);
  const [filteredImages, setFilteredImages] = useState([]);
  const [numFilter, setNumFilter] = useState({ name: ''});
  const [searchInput, setSearchInput] = useState('');
  const [filters, setFilters] = useState({
    backgrounds: '',
    sigils: '',
    helmets: '',
    uniforms: '',
    rarity: '',
  });
  const [sortBy, setSortBy] = useState('name up');
  const deviceType = useDeviceType();

  const getImageArrayForTab = (tab) => {
    switch(tab) {
      case 0:
        return sortImages(sortBy, metaData.collection);
      case 1:
        return sortImages(sortBy, dogizenMetaData.collection);
      default:
        return [];
    }
  };

  useEffect(() => {
    setFilteredImages(sortImages(sortBy, filteredImages))
  },[sortBy]);

  useEffect(()=>{
    const newImageArray = getImageArrayForTab(currentTab);

    setFilteredImages(sortImages(sortBy, newImageArray));
  }, [currentTab]);

  const handleNumSearch = (numSearch) => {
    const currentImageArray = getImageArrayForTab(currentTab);

    const filteredItems = currentImageArray.filter(item => {
      return item.name.toLowerCase().includes(numSearch.name.toLowerCase());
    });

    setFilteredImages(sortImages(sortBy, filteredItems));
  };

  const handleReset = () => {
    setFilteredImages(sortImages(sortBy, getImageArrayForTab(currentTab)));
    setNumFilter({ name: ''});
    setSearchInput('');
    {currentTab === 0 ? (setFilters({backgrounds: '',sigils: '',helmets: '',uniforms: '', rarity: ''})
    ):(
      setFilters({backgrounds: '',helmets: '',uniforms: '', rarity: ''}))};
    setSortBy('name up');
  };

  const handleFilterChange = (filterResult) => {
    const currentImageArray = getImageArrayForTab(currentTab);

    let filteredItems = currentImageArray.filter(item => {
      return Object.entries(filterResult).every(([key, value]) => {
        if (key === 'rarity') {
          return item.rarity.rank === value;
        }

        const attribute = item.attributes.find(
          attr => attr.trait_type.toLowerCase() === key.toLowerCase()
        );

        return attribute && attribute.value === value;
      });
    });

    console.log("Filtered items before rarity check:", filteredItems);

    if (filterResult.rarity) {
      filteredItems = filteredItems.filter(item => {
        console.log("Checking item rarity:", item.rarity.rank, "against", filterResult.rarity);
        return item.rarity.rank === filterResult.rarity;
      });
    }

    console.log("Filtered items after rarity check:", filteredItems);

    filteredItems = sortImages(sortBy, filteredItems);

    setFilteredImages(filteredItems);
  };

  const sortImages = (criteria, collection) => {
    let sortedImages;

    if (criteria === 'name up') {
      sortedImages = [...collection].sort((a, b) => {
        return a.name.slice(1) - b.name.slice(1);
      });
    } else if (criteria === 'name down'){
      sortedImages = [...collection].sort((a, b) => {
        return b.name.slice(1) - a.name.slice(1);
      });
    } else if (criteria === 'position up') {
      sortedImages = [...collection].sort((a, b) => {
        return a.rarity.position - b.rarity.position; // Assuming rarity is defined in each image
      });
    } else if (criteria === 'position down') {
      sortedImages = [...collection].sort((a, b) => {
        return b.rarity.position - a.rarity.position; // Assuming rarity is defined in each image
      });
    }

    return sortedImages;
  };

  return (
    <CollectionStyled>
      <Stack>
        <Stack
          className="title title-bottom-bar"
          direction={'column'}
          spacing={0.5}
        >
          <Typography className="big-title">Collections</Typography>
          <Typography className="description">
            Welcome back, Moonizen
          </Typography>
        </Stack>
        <Box>
          <Grid container spacing={4}>
            {deviceType === 'mobile' ? (<><Grid item lg={9} xs={12} sx={{ paddingBottom: 0 }}>
              <GenesisAside filteredImages={filteredImages}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              setSortBy={setSortBy}
              sortBy={sortBy}
              handleReset={handleReset}/>
            </Grid><Grid item lg={3} xs={12} sx={{ paddingTop: 0 }}>
              <FilterAside handleFilterChange={handleFilterChange} 
              handleNumSearch={handleNumSearch} 
              handleReset={handleReset} 
              currentTab={currentTab} 
              numFilter={numFilter} 
              setNumFilter={setNumFilter}
              searchInput={searchInput}
              setSearchInput={setSearchInput} 
              filters={filters}
              setFilters={setFilters}/>
            </Grid>
            </>
            ):(<><Grid item lg={3} xs={12}>
              <FilterAside handleFilterChange={handleFilterChange} 
              handleNumSearch={handleNumSearch} 
              handleReset={handleReset} 
              currentTab={currentTab} 
              numFilter={numFilter} 
              setNumFilter={setNumFilter}
              searchInput={searchInput}
              setSearchInput={setSearchInput} 
              filters={filters}
              setFilters={setFilters}/>
            </Grid>
            <Grid item lg={9} xs={12}>
              <GenesisAside filteredImages={filteredImages}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              setSortBy={setSortBy}
              sortBy={sortBy}
              handleReset={handleReset}/>
            </Grid></>)}
          </Grid>
        </Box>
      </Stack>
    </CollectionStyled>
  );
};

export default Collection;
