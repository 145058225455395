import { Box, Typography, Modal, Stack, Button, Grid } from '@mui/material';
import CollectionViewStyled from './CollectionView.styled';
import GradientBorderBox from '../../GradientBorderBox';

import metaData from '../../../abis/metadata.json';
import Dogizen_metadata from '../../../abis/Dogizen_metadata.json';

import Traits from './Traits';

const CollectionView = ({ isOpen, setIsOpen, nft, currentTab }) => {

  const fullName = () => {
    switch(currentTab) {
      case 0:
        return metaData.name;
      case 1:
        return Dogizen_metadata.name;
      default:
        return metaData.name;
    }
  }

  const collectionName = () => {
    switch(currentTab) {
      case 0:
        return "Moonizen";
      case 1:
        return "Dogizen";
      default:
        return "Moonizen";
    }
  }

  const imagePath = (nft) => {
    switch(currentTab) {
      case 0:
        return require(`../../../assets/images/Moonizen Genesis Images/${nft.image}`);
      case 1:
        return require(`../../../assets/images/Dogizen Images/${nft.image}`);
      default:
        return require(`../../../assets/images/Moonizen Genesis Images/${nft.image}`);
    };
  };

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <CollectionViewStyled>
        <Box className="modal-inner-wrapper">
          <Box className="modal-inner">
            <GradientBorderBox className="modal-content">
              <Box className="box-content">
                <GradientBorderBox className="top-header-box">
                  <Stack direction={'row'} spacing={4.5} className="top-box">
                    <Box className="centering">
                      <GradientBorderBox className="image">
                        <img src={imagePath(nft)} alt="" /> 
                      </GradientBorderBox>
                    </Box>
                    <Box className="top-header-desc">
                      <Typography className="bg-title">
                        {fullName()}
                      </Typography>
                      <Typography mt={1 / 2} className="sm-title">
                        {collectionName()} {nft.name}
                      </Typography>
                      <Typography mt={1 / 2} className="sm-title">
                        RANK: {nft.rarity.position}
                      </Typography>
                      <Typography mt={1 / 2} className="sm-title">
                        CATEGORY: ART
                      </Typography>
                      <Typography mt={3} className="content" style={{paddingRight:10}}>
                        {nft.description}
                      </Typography>
                    </Box>
                  </Stack>
                </GradientBorderBox>
                <Box mt={2} sx={{paddingBottom:'20px'}}>
                  <Traits traits={nft.attributes}/>
                </Box>
              </Box>
              <Grid container spacing={2} justifyContent="center"> 
                  <Box className="spacer">
                    <Grid item>
                      <Button className="close-button" onClick={() => setIsOpen(false)}>CLOSE</Button>
                    </Grid>
                  </Box>
              </Grid>
            </GradientBorderBox>
          </Box>
        </Box>
      </CollectionViewStyled>
    </Modal>
  );
};

export default CollectionView;
