import { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import VideoStyled from './index.styled';

import VideoUnit from '../../components/Pages/Video/VideoUnit';


const Video = () => {

  return (
    <VideoStyled>
      <Stack>
        <Stack
          className="title title-bottom-bar"
          direction={'column'}
          spacing={0.5}
        >
          <Typography className="big-title">Mooni Talez</Typography>
          <Typography className="description">
            Welcome back, Moonizen
          </Typography>
        </Stack>
            <Box>
                <VideoUnit />
            </Box>
      </Stack>
    </VideoStyled>
  );
};

export default Video;