import styled from '@emotion/styled';
import DexbotImage from '../../../assets/images/Dexbot_Aside.png';
import DexbotLandscape from '../../../assets/images/Dexbot_Aside_Landscape.png';
import useDeviceType from '../../../hooks/useDeviceType';

const GenesisRightAsideStyled = styled.div`
  height: 100%;

  .genesis-right-box {
    border-radius: 13px;
    background-image: url(${(props) => props.image});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    cursor: pointer;
  }

  @media (max-width: 765px){
    height: 250px;
  }

  @media (max-width: 575px){
    height: calc(0.354 * 100vw - 3.55px);
  }
`;

const ImageRightAside = () => {
  const deviceType = useDeviceType();
  return deviceType === "mobile" ? DexbotLandscape : DexbotImage;
};

export { GenesisRightAsideStyled, ImageRightAside };
