import styled from '@emotion/styled';

const FilterResultBoxStyled = styled.div`
  ul {
    background-color: #333;
    color: #fff;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  margin-top: 24px;
  background: linear-gradient(
    180deg,
    rgba(7, 72, 91, 0.6) 0%,
    rgba(7, 72, 91, 0.2) 100%
  );
  background-blend-mode: multiply;
  height: 535px;

  .filter-result-box {
    height: 100%;
    border-radius: 4px;
    padding: 19px 9px 19px 18px;

    .filters-wrapper {
      overflow-y: scroll;
      height: 100%;
      display: flex;
      flex-direction: column;
      grid-template-columns: 1fr;
      /* gap: 16px; */

      &::-webkit-scrollbar {
        width: 1px;
        height: 1px;
        background: inherit;
        border-radius: 2px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background: #47c3c1;
        background-blend-mode: multiply;
      }

      .filter-control {
        margin-bottom: 10px;
        .MuiFormControl-root {
          width: 100%;
        }

        .MuiSelect-select,
        .MuiSelect-outlined {
          color: ${(props) => props.theme.palette.fontColor.secondary};
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .MuiOutlinedInput-root {
          fieldset {
            border-color: rgba(71, 195, 193, 0.4);
          }
          
          &:hover fieldset {
            border-color: rgba(71, 195, 193, 0.6);
          }
          
          &.Mui-focused fieldset {
            border-color: #47c3c1;
          }
        }
      }
    }
  }

  @media (max-width: 574px) {
    height: 478px;
  }
`;

export default FilterResultBoxStyled;
