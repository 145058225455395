import styled from '@emotion/styled';

const VideoListStyled = styled.div`

.video-list-box{
    overflow: auto;

    .big-title {
        font-size: 20px;
        margin-top: 10px;
    }

    .accordion-title{
        cursor: pointer;
        font-family: Geom !important;
    }

    .title-pieces {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .section-title{
            font-family: Geom;
            font-size: 30px;
        }
    }

    .display-button{
        padding-right: 20px;
    }

    .description {
        cursor: pointer;
        margin-top: 10px;
        font-size: 20px;
        font-family: Geom;
    }

    .big-title:first-child {
        margin-top: 15px;
    }

    .description:first-child {
        margin-top: 15px;
    }
}

`;

export default VideoListStyled;