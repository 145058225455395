import WalletAvatarStyled from './WalletAvatarStyled';
import GradientBorderBox from '../../GradientBorderBox';
import { Button } from '@mui/material';

const WalletAvatar = ({ onClick, src, rank }) => {
  return (
    <WalletAvatarStyled>
      <GradientBorderBox className={`genesis-avatar-box ${rank}`}>
        <Button
          onClick={() => onClick()}
          className="bg-box"
          sx={{ backgroundImage: `url(${src})`}}
        />
      </GradientBorderBox>
    </WalletAvatarStyled>
  );
};

export default WalletAvatar;

