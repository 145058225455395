import styled from '@emotion/styled';

const VideoPlayerStyled = styled.div`

.video-container {
    height: calc( 595px * 0.892 );
}

.plyr {
    height: 95%;
    border-radius: 0.5rem;
}

.plyr__video-wrapper {
    height: 100%;
    border-radius: 0.5rem;
}

`;

export default VideoPlayerStyled;