import { GenesisRightAsideStyled, ImageRightAside } from './GenesisRightAsideStyled';

import { Box } from '@mui/material';

const GenesisRightAside = () => {
  const imageRightAside = ImageRightAside();

  return (
    <GenesisRightAsideStyled image={imageRightAside}>
      <Box className="genesis-right-box" onClick={() => { window.open("https://app.mydexbot.com/auth/signup/Moonizen88dc3422", "_blank") }} />
    </GenesisRightAsideStyled>
  );
};

export default GenesisRightAside;
