import { useState } from 'react';
import { Box, Typography, Button, IconButton, Tooltip } from '@mui/material';

import FilterStyled from './index.styled';
import SearchBar from '../SearchBar';
import SearchIcon from '../../assets/images/search-normal.svg';
import RefreshIcon from '@mui/icons-material/Refresh';

const Filter = ({ onNumberSearch, handleReset, numFilter, setNumFilter, searchInput, setSearchInput}) => {

  const handleChange = (event) => {
    // Update the numFilter state with the input value
    setNumFilter(event);
  };

  const handleSearch = () => {
    // Create the search object only if numFilter is not empty
    if (numFilter.trim()) {
      const numSearch = { name: numFilter };
      // Call the onNumberSearch prop with the search object
      onNumberSearch(numSearch);
    }
  };

  const handleKeyPress = (event) => {
    // Trigger search when Enter key is pressed
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <FilterStyled>
      <Box className="filter">
        <Typography className="title">Filters</Typography>
          <SearchBar 
            className="collection-filter" 
            placeholder="Search Moonizens by Number..." 
            value={numFilter}
            onValueChange={handleChange}
            onKeyPress={handleKeyPress}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
            <Tooltip title="Reset the filters" arrow>
              <IconButton 
                onClick={handleReset} 
                sx={{ ml: 1,
                  color:'#95B1AF',
                  '&:hover': {
                    backgroundColor:"rgba(255,255,255, 0.01)"
                  }
                }}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
            <Button onClick={handleSearch} className="search-icon">
              <img src={SearchIcon} alt="Search" />
            </Button>
      </Box>
    </FilterStyled>
  );
};

export default Filter;
