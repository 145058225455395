import {useState, useEffect} from 'react';
import { Box, Stack, Typography } from '@mui/material';
import GradientBorderBox from '../../components/GradientBorderBox';

import GalleryStyled from './index.styled';

const Gallery = () => {

    return (
        <GalleryStyled>
            <Stack>
                <Stack
                className="title title-bottom-bar"
                direction={'column'}
                spacing={0.5}
                >
                <Typography className="big-title">3D GALLERY</Typography>
                <Typography className="description">
                    Welcome back, Moonizen
                </Typography>
                </Stack>
                <GradientBorderBox className='container'>
                    <iframe className="pip" src="https://cdn.doggy.market/content/db931e70d1e7dce25772e6c4e3bcc7b7fe9c16d7389083d02715649ab06cf935i0" />
                </GradientBorderBox>
            </Stack>
        </GalleryStyled>
      );
    };
export default Gallery;