import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import Plyr from 'plyr-react';
import 'plyr-react/plyr.css';

import useDeviceType from '../../../hooks/useDeviceType';

import VideoPlayerStyled from './VideoPlayer.styled';


const VideoPlayer = ({currentVideo}) => {
    const [isYoutube, setIsYoutube] = useState(true);
    const [loading, setLoading] = useState(true);
    const deviceType = useDeviceType();

    useEffect(() => {
        setIsYoutube(currentVideo.source === 'youtube');
        setLoading(true);

        const timer = setTimeout(() => {
            setLoading(false);
        }, 500);

        return () => clearTimeout(timer);
    }, [currentVideo])

    if (loading) {
        return <div>Loading...</div>;
    }

    const plyrProps = isYoutube ? ({
        source: {
            type: 'video',
            title: `${currentVideo.title}`,
            sources: [{
                src: `${currentVideo.url}`,
                provider: `${currentVideo.source}`,
            }],
        },
        options: {
            autoplay: true,
            controls: ['play-large', 'play', 'progress', 'current-time', 'volume', 'mute', 'fullscreen'],
            ratio: deviceType==='mobile' ? '9:16':'16:9',
        },
    }) : ({
        source: {
            type: 'video',
            title: `${currentVideo.title}`,
            sources: [{
                src: require(`../../../assets/videos/${currentVideo.url}`),
                type: `${currentVideo.type}`,
            }],
        },
        options: {
            autoplay: true,
            controls: ['play-large', 'play', 'progress', 'current-time', 'volume', 'mute', 'fullscreen'],
            ratio: deviceType==='mobile' ? '9:16':'16:9',
        },
    });

    return(
        <VideoPlayerStyled>
            <Box className='video-container'>
                <Plyr {...plyrProps} />
            </Box>
        </VideoPlayerStyled>
    )
}

export default VideoPlayer;