import { useState, useEffect } from 'react';
import GradientBorderBox from '../../GradientBorderBox';
import VideoList from './VideoList';
import VideoPlayer from './VideoPlayer';
import { Box, Grid, Stack, Typography } from '@mui/material';

import VideoUnitStyled from './VideoUnit.styled';

import videoList from '../../../abis/Video_list.json'

const VideoUnit = () => {

    const [currentVideo, setCurrentVideo] = useState(videoList.collection[0].episodes[0])

    useEffect(()=>{
    }, [currentVideo])

    return(
        <VideoUnitStyled>
            <Box className="media-adjustor">
                <GradientBorderBox className="holder rounded-border">
                    <Grid container direction='row' spacing={2} className='content'>
                        <Grid item className="test rounded-border" md={12} lg={3}>
                            <Stack>
                                <Typography className='video-list-title big-title'>Video List</Typography>
                                <VideoList onSelect={setCurrentVideo}/>
                            </Stack>
                        </Grid>
                        <Grid item className="video-player" md={12} lg={8}>
                            <GradientBorderBox>
                                <VideoPlayer key={currentVideo.url} currentVideo={currentVideo} />
                            </GradientBorderBox>
                        </Grid>
                    </Grid>
                </GradientBorderBox>
            </Box>
        </VideoUnitStyled>
    )
};

export default VideoUnit;