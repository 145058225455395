import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';

import VideoListStyled from './VideoList.styled';
import Accordion from './Accordion';

import videoList from '../../../abis/Video_list.json';

const VideoList = ({ onSelect }) => {
    const [activeIndex, setActiveIndex] = useState(-1);

    const handleAccordionClick = (index) => {
        setActiveIndex(index === activeIndex ? -1 : index);
    };

    const handleClick = (title) => {
        onSelect(title); // Call the parent function with the selected title
    };

    return (
        <VideoListStyled>
            <Box className="video-list-box">
                {videoList.collection.map((category) => (
                    <Accordion
                        category={category}
                        activeIndex={activeIndex}
                        onAccordionClick={handleAccordionClick}
                        onSelect={handleClick}
                    />
                ))}
            </Box>
        </VideoListStyled>
    );
}

export default VideoList;